const toolsRoute = {
    path: '/tools', 
    name: 'tools',
    redirect:'/tools/calculator',
    component:(resolve) => require(['@/views/tools/index'], resolve),
    children: [
      {
        path: 'calculator',
        name: 'calculator',
        component:(resolve) => require(['@/views/tools/calculator'], resolve),
      }
    ]
  }
  export default toolsRoute
  