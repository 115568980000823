import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'
import './assets/css/common.less';
import "ant-design-vue/dist/antd.css";


import Vue from "vue";

import VueI18n from 'vue-i18n';
import VueCookies from 'vue-cookies'
import {
	BootstrapVue
} from 'bootstrap-vue'
if (typeof window !== 'undefined') {
	const Share = require('vue-social-share').default
	Vue.use(Share)
}
import * as echarts from "echarts";
import { message } from 'ant-design-vue'
import preventClick from '../src/plugins/preventClick'  // 根据自己的路径

// 引入插件
import VueSweetalert2 from './plugins/vue-sweetalert2'

import App from './App.vue';

import {
	createRouter
} from "./router";
import {
	createStore
} from "./store";

Vue.prototype.$originHost = "https://standby-api.fgas.io"
if (process.env.NODE_ENV === 'production') {
	if (window.location.origin.indexOf('filestations') !== -1) {
		// Vue.prototype.$originHost = `https://gas.filestations.com`
		Vue.prototype.$originHost = `https://standby-api.fgas.io`
	} else if (window.location.origin.indexOf('localhost') !== -1) {
		Vue.prototype.$originHost = `http://localhost:9095`
	}
} else if (process.env.NODE_ENV === 'development') {
	Vue.prototype.$originHost = 'https://standby-api.fgas.io'
}
Vue.prototype.$message = message;
Vue.prototype.$echarts = echarts;
message.config({
  duration: 1,
});

// 使用插件
Vue.use(VueSweetalert2)
Vue.use(VueCookies)
Vue.use(VueI18n) // 通过插件的形式挂载
Vue.use(BootstrapVue)
Vue.use(preventClick) //阻止连续点击


const i18n = new VueI18n({
	locale: 'zh-CN', // 语言标识
	//this.$i18n.locale // 通过切换locale的值来实现语言切换
	messages: {
		'zh-CN': require('./assets/common/lang/zh'), // 中文语言包
		'en-US': require('./assets/common/lang/en') // 英文语言包
	}
})


Vue.config.productionTip = false;


const router = createRouter();
const store = createStore();
new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app');
