const homeRoute = {
    path: '/',
    component: (resolve) => require(['@/views/home/Home'], resolve),
    redirect: '/',
    children: [
      {
        path: '/',
        name: 'home',
        component: (resolve) => require(['@/views/home/Home'], resolve),
      }
    ]
  }
  export default homeRoute
  