export const m = {
  switchLang: "中文",
  home: "Home",
  calculator: "Calculator",
  news: "News",
  findMusic: "FIND MUSIC",
  myMusic: "MY MUSIC",
  friend: "FRIEND",
  musician: "MUSICIAN",
  download: "DOWNLOAD",
  refreshTime: "Update Time",
  realTime: "Real Time",
  mining: "Mining",
  fee: "Fee",
  h1Title: "Real-time Filecoin Mining Gas Queries",
  indexTitle: "Real-time mining costs",
  everyG: "Every 32 GiB",
  perT: "Per TiB",
  perP: "Per PiB",
  totalCost: "Total Cost of Sealing Sector",
  PledgeFee: "Sealing Sector Pledge Fees",
  gasFee: "Sealing Sector Gas Fees",
  coinTitle: "Trading Platform Data",
  longPress: "Long Press the Image to Save and Share",
  share: "Share",
  plat: "Platform",
  source: "Source",
  price: "Price",
  volume24h: "24-hour Volume",
  liquidity: "Liquidity",
  voteTitle: "How do you feel about Filecoin today?",
  blockHeight: "Block Height",
  wiki: "Wiki",
  tools: "Tools",
  calculator: "Input-Output Calculator",
  minerId: "Miner ID",
  minerName: "Name",
  minerQualityAdjPowerProportion: "Effective computing power/%",
  blockRewards: "Block reward",
  efficiency: "Mining efficiency",
  powerGrowth: "Incremental computing power",
  diff32Title:'List of 32GiB active miners in the past 24h',
  diff64Title:'List of 64GiB active miners in the past 24h',
  sectorPieTitle:'The proportion of active miners in the entire network',
  miners:'Number of miners',
};
