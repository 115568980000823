import Vue from "vue";
import Vuex from "vuex";
const axios = require("axios");

let originHost = "https://standby-api.fgas.io";
if (process.env.NODE_ENV === "production") {
  if (window.location.origin.indexOf("filestations") !== -1) {
    // originHost = `https://gas.filestations.com`;
    originHost = `https://standby-api.fgas.io`;
  } else if (window.location.origin.indexOf("localhost") !== -1) {
    originHost = `http://localhost:9095`;
  }
} else if (process.env.NODE_ENV === "development") {
  originHost = `https://standby-api.fgas.io`;
}

Vue.use(Vuex);

const state = {
  count: 0,
  article: [],
  article64: [],
  coin: [],
  miners: [],
  wiki: {},
  increase_power: {},
};

const mutations = {
  increment(state) {
    state.count++;
  },
  decrement(state) {
    state.count--;
  },
  SET_ARTICLE(state, data) {
    //基础数据
    state.article = data;
  },
  SET_ARTICLE64(state, data) {
    //基础数据
    state.article64 = data;
  },
  SET_COIN(state, data) {
    //币价
    state.coin = data;
  },
  SET_MINERS(state, data) {
    state.miners = data;
  },
  SET_WIKI(state, data) {
    //知识库
    state.wiki = data;
  },
  SET_INCREASE_POWER(state, data) {
    //全网算力增速
    state.increase_power = data;
  },
};

const actions = {
  increment: ({ commit }) => {
    commit("increment");
  },
  decrement: ({ commit }) => {
    commit("decrement");
  },
  async GET_ARTICLE({ commit }, params) {
    const { data } = await axios
      .get(`${originHost}/api/v1/fil?type=${params.type}`)
      .catch((err) => {
        return false;
      });
    if (params.type === '32G') { //区分32/64
      commit("SET_ARTICLE", data.result[0]);
    } else {
      commit("SET_ARTICLE64", data.result[0]);
    }
  },
  async GET_COIN({ commit }) {
    const { data } = await axios
      .get(`${originHost}/api/v1/coin`)
      .catch((err) => {
        return false;
      });
    if (data) {
      commit("SET_COIN", data.result["coins"]);
    } else {
      commit("SET_COIN", {});
    }
  },
  async GET_MINERS({ commit }) {
    const { data } = await axios
      .get(`${originHost}/api/v1/miner`)
      .catch((err) => {
        return false;
      });
    if (data) {
      commit("SET_MINERS", data);
    } else {
      commit("SET_MINERS", {});
    }
  },
  async GET_WIKI({ commit }) {
    const { data } = await axios
      .get(`${originHost}/api/v1/wiki`)
      .catch((err) => {
        return false;
      });
    if (data) {
      commit("SET_WIKI", data);
    } else {
      commit("SET_WIKI", {});
    }
  },
  async GET_INCREASE_POWER({ commit }) {
    const { data } = await axios
      .post(`${originHost}/api/v1/power`)
      .catch((err) => {
        return false;
      });
    if (data) {
      commit("SET_INCREASE_POWER", data);
    } else {
      commit("SET_INCREASE_POWER", {});
    }
  },
  async GET_TOP20MINER({ commit }) {
    const { data } = await axios
      .post(`${originHost}/api/v1/getMiners`)
      .catch((err) => {
        return false;
      });
    // commit('SET_WIKI', data)
  },
  async GET_STATICINFO({ commit }) {
    const { data } = await axios
      .post(`${originHost}/api/v1/getStatisticsInfo`)
      .catch((err) => {
        return false;
      });
  },
  async GET_DAYTRENDINFO({ commit }) {
    const { data } = await axios
      .post(`${originHost}/api/v1/getDayTrendInfo`, {
        type: "reward",
      })
      .catch((err) => {
        return false;
      });
  },
  async GET_WEEKTRENDINFO({ commit }) {
    const { data } = await axios
      .post(`${originHost}/api/v1/getWeekTrendInfo`, {
        type: "power",
      })
      .catch((err) => {
        return false;
      });
  },
};

const getters = {
  count(state) {
    return state.count;
  },
  getOdd(state) {
    return state.count % 2 == 0 ? "偶数" : "奇数";
  },
};

// export default new Vuex.Store({
//   state: {},
//   mutations: {},
//   actions: {},
//   modules: {}
// });

export function createStore() {
  return new Vuex.Store({
    state,
    mutations,
    actions,
    getters,
    modules: {},
  });
}
