import homeRoute from './home'
import wikiRoute from './wiki'
import toolsRoute from './tools'

const routes = [
  homeRoute,
  wikiRoute,
  toolsRoute
]
export default routes
