<template>
  <b-navbar toggleable="lg" type="dark">
    <b-container fluid class="fgNavItem">
      <b-navbar-brand href="https://calculator.ssdminer.cn/#/">
        <img src="img/calc.png" alt="" />FIL计算器
      </b-navbar-brand>

      <b-navbar-item class="navLeft">
        <img src="img/gas.png" alt="" />Gas实时查
      </b-navbar-item>
    </b-container>
  </b-navbar>
</template>
<script>
export default {
  computed: {
    navLinks() {
      return [
        {
          name: this.$t("m.home"),
          url: "/index",
        },
        {
          name: this.$t("m.wiki"),
          url: "/wiki",
        },
        {
          name: this.$t("m.calculator"),
          url: "/tools/calculator",
        },
      ];
    },
  },
  data() {
    return {
      zhLang: true,
    };
  },
  watch: {},
  methods: {
    setLang() {
      this.lang = navigator.language || navigator.userLanguage; //常规浏览器语言和IE浏览器
      if (this.lang === "en") {
        this.zhLang = false;
        this.lang = "en-US";
        this.$i18n.locale = this.lang;
      } else {
        this.zhLang = true;
        this.lang = "zh-CN";
        this.$i18n.locale = this.lang;
      }
    },
    switchLang() {
      if (this.lang === "zh-CN") {
        this.zhLang = false;
        this.lang = "en-US";
        this.$i18n.locale = this.lang;
      } else {
        this.zhLang = true;
        this.lang = "zh-CN";
        this.$i18n.locale = this.lang; //关键语句
      }
    },
  },
  created() {},
  mounted() {
    setTimeout(() => {
      this.setLang();
    }, 50);
  },
};
</script>
<style lang="less" scoped>
.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 1.2555rem;
  padding-right: 1rem;
  padding-bottom: 1.2555rem;
  padding-left: 1rem;
  font-size: 1.2555rem;
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
  &.navbar-dark {
    background-color: #0cc2d3;
    /deep/.fgNavItem {
      margin: 0 10.5555rem;
      position: relative;
      .navbar-brand {
        position: absolute;
        margin-right: 0;
        right: 0;
        left: unset;
        top: 0;
        // transform: translateX(-50%);
        padding: 0;
        .navbarBrandImg {
          max-width: 100%;
          max-height: 2.5rem;
        }
      }
      .navLeft {
        margin-left: 15px;
        line-height: 23px;
        color: #fff;
        font-weight: 700;
        font-size: 18px;
        img {
          float: left;
          width: 20px;
          height: 20px;
          margin-right: 6px;
        }
      }
      #nav-collapse {
        &:before {
          display: none;
        }
        .nav {
          margin-left: 7rem;
          margin-top: 0 !important;
          border-top: 0 !important;
          .nav-item {
            .nav-link {
              font-weight: 400;
              line-height: 1.5;
              color: #dfdddd;
              &.active,
              &:hover {
                color: #fcfcfc;
                transition: color 0.15s ease-in-out,
                  background-color 0.15s ease-in-out,
                  border-color 0.15s ease-in-out;
              }
            }
          }
        }
      }
      .switchLang {
        position: absolute;
        top: 0;
        right: 0;
        width: 80px;
        height: 40px;
        padding-left: 26px;
        line-height: 40px;
        cursor: pointer;
        color: #dfdddd;
        border-radius: 5px;
        text-align: center;
        .iconfont {
          position: absolute;
          left: 5px;
          top: 0;
          margin-left: 5px;
          &:before {
            font-size: 26px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .fgNavItem {
    margin: 0 10.5555rem;
  }
}
@media only screen and (max-width: 768px) {
  .navbar {
    padding-left: 0;
    padding-right: 0;

    &.navbar-dark {
      background-color: #0cc2d3;
      // border-bottom: 1px solid #f1f1f1;
      .fgNavItem {
        margin: 0;
        padding: 0;
        .navbar-brand {
          position: absolute;
          left: unset;
          top: 50%;
          right: 10px;
          margin-top: -7.5px;
          line-height: 1;
          img {
            width: 10px;
            height: 12px;
            margin-right: 10px;
            vertical-align: baseline;
          }
        }

        .navLeft {
          margin-left: 15px;
          line-height: 23px;
          color: #fff;
          font-weight: 700;
          font-size: 18px;
          img {
            float: left;
            width: 20px;
            height: 20px;
            margin-right: 6px;
          }
        }
        .navbar-toggler {
          margin-left: 1rem;
        }

        #nav-collapse {
          .nav {
            margin-left: 0;
            display: block;
            margin-top: 1.5rem;
            padding-top: 0.5rem;
            border-top: 1px solid #264367;
            .nav-item {
              overflow: hidden;
              position: relative;
              .nav-link {
                padding-left: 1.5rem;
                &.active {
                  &:after {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 0.5rem;
                    bottom: 0.5rem;
                    border-left: 2px solid #2c7be5;
                  }
                }
              }
            }
          }
        }
        .switchLang {
          top: 0;
          right: 0;
          width: 80px;
          line-height: 34px;
        }
      }
    }
  }
}
</style>
