export const m = {
  switchLang: 'En',
  home: '首页',
  calculator: '计算器',
  news: '资讯',
  findMusic: '发现音乐',
  myMusic: '我的音乐',
  friend: '朋友',
  musician: '音乐人',
  download: '下载客户端',
  refreshTime: '更新时间',
  realTime: '实时',
  mining: '挖矿',
  fee: '费',
  h1Title: 'Filecoin挖矿成本实时查询',
  indexTitle: '实时挖矿成本',
  everyG: '每 32 GB',
  perT: '每 1 TB',
  perP: '每 1 PB',
  totalCost: '封装扇区总成本',
  PledgeFee: '封装扇区质押费',
  gasFee: '封装扇区Gas费',
  coinTitle: '交易平台数据',
  longPress: '长按图片保存分享',
  share: '分享',
  source: '来源',
  plat: '交易平台',
  price: '价格',
  volume24h: '24小时成交量',
  liquidity: '流动性',
  voteTitle:'今天你对filecoin的看法是?',
  blockHeight:'区块高度',
  wiki:'知识库',
  tools:'小工具',
  calculator:'投入产出计算器',
  minerId:'矿工号',
  minerName:'标签',
  minerQualityAdjPowerProportion:'有效算力/占比',
  blockRewards:'出块奖励',
  efficiency:'挖矿效率',
  powerGrowth:'算力增量',
  diff32Title:'近24h 32GiB活跃矿工列表',
  diff64Title:'近24h 64GiB活跃矿工列表',
  sectorPieTitle:'全网活跃矿工数量占比',
  miners:'矿工数',
}