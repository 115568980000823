import Vue from "vue";
import VueRouter from "vue-router";
import routes from './modules/index'

Vue.use(VueRouter);

export function createRouter() {
	return new VueRouter({
		// mode: 'history', //一定要是history模式
		routes
	});
}
